import { useEffect } from "react";
import { authenticateUserApi } from "@/api/user";
import { useUserStore } from "@/stores/user";
import { useShallow } from "zustand/shallow";
import { useUser } from "@clerk/clerk-react";

let authInitialized = false;

export function useAuthentication() {
  const { setUser, user } = useUserStore(useShallow((s) => s));
  const clerkUser = useUser();
  useEffect(() => {
    initAuth();
  }, [clerkUser.isLoaded, clerkUser.isSignedIn]);

  async function initAuth() {
    if (user !== undefined || authInitialized) {
      return;
    }

    if (!clerkUser.isSignedIn) {
      return;
    }
    authInitialized = true;

    const authUser = await authenticateUserApi({
      email: clerkUser.user.primaryEmailAddress?.emailAddress as string,
      ssoId: clerkUser.user.id,
      firstName: clerkUser.user.firstName,
      lastName: clerkUser.user.lastName,
      profilePictureUrl: clerkUser.user.imageUrl,
    });

    setUser(authUser);
  }

  return user;
}
