import { RouterProvider, createRouter } from "@tanstack/react-router";

import { routeTree } from "@/routeTree.gen";
import { SignIn, useAuth } from "@clerk/clerk-react";
import { useAuthentication } from "./hooks/useAuthentication";

const router = createRouter({ routeTree });

function App() {
  useAuthentication();
  const { userId } = useAuth();

  if (userId === undefined) {
    return <div>Loading...</div>;
  }

  if (userId === null) {
    return (
      <div className="h-screen flex items-center justify-center">
        <SignIn />
      </div>
    );
  }

  return <RouterProvider router={router} />;
}

export default App;
