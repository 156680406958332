import { createRootRoute, Outlet } from "@tanstack/react-router";
import TanStackRouterDevtools from "@/components/TankStackDevTools";

export const Route = createRootRoute({
  component: () => (
    <>
      <Outlet />
      {import.meta.env.DEV ?? <TanStackRouterDevtools />}
    </>
  ),
});
