import { UserInviteBody } from "@/types";
import { deleteRequest, getRequest, postRequest } from "./base.api";

export type LoggedInUser = {
  id: string;
  role: string;
  firstName: string | null;
  lastName: string | null;
};

type AuthenticateUserBody = {
  email: string;
  ssoId: string;
  firstName: string | null;
  lastName: string | null;
  profilePictureUrl: string | null;
};

export type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  profilePictureUrl: string;
  role: string;
  projects: number[];
  active: boolean;
  organizationId: string;
  organizationName: string;
};

export async function authenticateUserApi(data: AuthenticateUserBody) {
  return postRequest<User>(`/users/auth`, data);
}

export async function getUsersApi() {
  return getRequest<User[]>(`/users`);
}

export function addUserToProjectApi(
  userId: number,
  projectId: number,
): Promise<User> {
  return postRequest(`/users/${userId}/project/${projectId}`);
}

export function removeUserFromProjectApi(
  userId: number,
  projectId: number,
): Promise<User> {
  return deleteRequest(`/users/${userId}/project/${projectId}`);
}

export async function inviteUserApi(params: UserInviteBody): Promise<User> {
  return postRequest<User>(`/users/invite`, params);
}
