import { create } from "zustand";
import { User } from "@/api/user";

interface UserState {
  user: User | null | undefined;
}

interface UserActions {
  setUser: (u: User | null) => void;
}

export const useUserStore = create<UserState & UserActions>()((set) => ({
  user: undefined,
  setUser: (u: User | null) => set({ user: u }),
}));
