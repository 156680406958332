/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'

// Create Virtual Routes

const UsersLazyImport = createFileRoute('/users')()
const IndexLazyImport = createFileRoute('/')()
const PProjectIdLazyImport = createFileRoute('/p/$projectId')()

// Create/Update Routes

const UsersLazyRoute = UsersLazyImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/users.lazy').then((d) => d.Route))

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const PProjectIdLazyRoute = PProjectIdLazyImport.update({
  id: '/p/$projectId',
  path: '/p/$projectId',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/p.$projectId.lazy').then((d) => d.Route))

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/users': {
      id: '/users'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof UsersLazyImport
      parentRoute: typeof rootRoute
    }
    '/p/$projectId': {
      id: '/p/$projectId'
      path: '/p/$projectId'
      fullPath: '/p/$projectId'
      preLoaderRoute: typeof PProjectIdLazyImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/users': typeof UsersLazyRoute
  '/p/$projectId': typeof PProjectIdLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/users': typeof UsersLazyRoute
  '/p/$projectId': typeof PProjectIdLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/users': typeof UsersLazyRoute
  '/p/$projectId': typeof PProjectIdLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths: '/' | '/users' | '/p/$projectId'
  fileRoutesByTo: FileRoutesByTo
  to: '/' | '/users' | '/p/$projectId'
  id: '__root__' | '/' | '/users' | '/p/$projectId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  UsersLazyRoute: typeof UsersLazyRoute
  PProjectIdLazyRoute: typeof PProjectIdLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  UsersLazyRoute: UsersLazyRoute,
  PProjectIdLazyRoute: PProjectIdLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/users",
        "/p/$projectId"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/users": {
      "filePath": "users.lazy.tsx"
    },
    "/p/$projectId": {
      "filePath": "p.$projectId.lazy.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
